import * as Sentry from '@sentry/vue'
import axios from 'axios'
import UIkit from 'uikit'
import VueAxios from 'vue-axios'

const ax = axios.create()

ax.interceptors.request.use(
  config => config,
  error => {
    if (!(error instanceof Error) && typeof error?.message === 'string') {
      Sentry.captureMessage(error.message)
    } else {
      Sentry.captureException(error)
    }

    return Promise.reject(error)
  }
)

ax.interceptors.response.use(
  res => res,
  async error => {
    if (error.response && [422].includes(error.response.status)) {
      return Promise.reject(error)
    }

    if (error.response && 419 === error.response.status) {
      UIkit.notification('Your session has expired: The page will automatically reload', {
        status: 'danger',
      })
      if (typeof window?.location?.reload === 'function') {
        window.location.reload()
      } else if (typeof document?.location?.reload === 'function') {
        document.location.reload()
      }
    } else if (!(error instanceof Error) && typeof error?.message === 'string') {
      Sentry.captureMessage(error?.message)
    } else {
      Sentry.captureException(error)
    }

    return Promise.reject(error.response || error)
  }
)

const useVueAxios = (app, store) => {
  app.use(VueAxios, ax)

  if (store) {
    store.axios = app.axios
  }
  app.provide('axios', app.config.globalProperties.axios)
}

export { ax as axios, useVueAxios }
